@import "~antd/dist/antd.css";
@import '../../overrides/style/OneDesignAnt.css';

.ant-table table {
  box-shadow: 0px 1px 5px rgba(105, 128, 145, 0.4);
  border-radius: 6px 6px 0 0;
  color: #002A3E;
}

.ant-table-container table > thead > tr:first-child th:first-child {
  border-top-left-radius: 6px;
}

.ant-table-container table > thead > tr:first-child th:last-child {
  border-top-right-radius: 6px;
}

.ant-table-tbody > tr > td {
  border-bottom: none;
}

.ant-table-tbody > tr > td.ant-table-cell.bold {
  font-weight: 500;
}

.ant-table-tbody > tr.ant-table-row-selected > td {
  background: white;
}

.ant-table-tbody > tr:nth-child(odd) > td {
  background: #F7F8F9;
}

.ant-table-tbody > tr.ant-table-row:hover > td {
  background: white;
}

.ant-table-tbody > tr.ant-table-row:hover > td {
  background: #E2F1FF;
}

td.ant-table-column-sort {
  background: initial;
}

.ant-table-thead > tr > th {
  background: white;
  font-size: 14px;
  font-weight: 500;
  color: #002A3E;
  padding: 16px;
  border-bottom: 1px solid #C3CCD3;
  font-family: 'Avenir Next',Verdana,Geneva,Tahoma,sans-serif;
}

.ant-table-thead > tr > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
  display: none;
}

.ant-table-thead th.ant-table-column-sort {
  background: white;
}

.ant-pagination-item {
  font-family: 'Avenir Next',Verdana,Geneva,Tahoma,sans-serif;
}

.show-on-row-hover {
  opacity: 0;
}

.ant-table-cell-row-hover .show-on-row-hover {
  opacity: 1;
}