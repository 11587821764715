.form-search-select > div.ant-select.ant-select-auto-complete.ant-select-single.ant-select-customize-input.ant-select-show-search > .ant-select-selector {
  border-right: solid 0 !important;
}

.form-search-select__no-value > input.vui-inputInput {
 color: #1a1a1980
}

.form-search-select__selected-value > input.vui-inputInput {
  color: #1a1a19
}