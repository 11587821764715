.toastui-editor-contents table {
  border: none;
}

.toastui-editor-contents table th {
  border: none;
  color:#002A3E;
}

.toastui-editor-contents table td {
  border: none;
}

.toastui-editor-contents table td {
  background: white;
}

.chart-figure ul > li::before {
  display: none
}

.chart-figure .recharts-tooltip-wrapper {
  outline: none;
}

.table-figure .vui-table {
  margin-inline: auto;
  margin-block: 8px;
  box-shadow: 0px 1px 6px #C3CCD3;
  color: #002A3E;
  box-shadow: 0px 1px 5px rgba(105, 128, 145, 0.4);
  border-radius: 6px;
  overflow: hidden;
}

.table-figure .vui-tr:nth-child( odd ) .vui-td {
  background: #F7F8F9;
}

.table-figure .vui-tr {
  font-size: 16px;
}

.table-figure .vui-thead th {
  background: white;
  border-bottom: 1px solid #C3CCD3;
  font-size: 14px;
  font-weight: 500;
}

.toastui-editor-defaultUI, .toastui-editor-contents {
  font-family: inherit;
}

.toastui-editor-defaultUI {
  border: none;
}

.toastui-editor-defaultUI .toastui-editor-md-tab-container {
  background: none;
}

.toastui-editor-defaultUI-toolbar {
  background: none;
}

:not(.auto-height) > .toastui-editor-defaultUI > .toastui-editor-main {
  margin-top: 12px;
}

.toastui-editor-defaultUI .ProseMirror {
  height: 100%;
}